

import DxList from 'devextreme-vue/list';
import JobTitleService from '../services/jobtitle-service';

// :selected-items="selectedJobTitles"

//import service from './data.js';

//const tasks = service.getTasks();

export default {
  name: "JobTitleTemplate",
  components: { DxList },
  props: {
    templateData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    const {
      nombre,
    } = this.templateData;
    //const employeeTasks = tasks.filter((task) => task.EmployeeID === this.templateData.ID);
    return {
      id_puesto: null,
      nombre: null,      
      dataSourceJobTitles: null,
      selectedJobTitles: [],
      //selectedJobTitles: skillTasks.filter((skill) => skill.nombre === "true"),
      detailInfo: `${nombre}`,      
    };
  },
  created() {
    this.jobTitleService = new JobTitleService();
    console.log('created jobtitle template');
  },
  mounted() {    
    this.jobTitleService.getPuestos().then(data => this.dataSourceJobTitles=data);
    console.log('mounted jobtitle template');
  },
};
